import React from 'react';
import logo1 from "../../assets/images/logoslider/1.png";
import logo2 from "../../assets/images/logoslider/2.png";
import logo3 from "../../assets/images/logoslider/3.png";
// import logo4 from "../../assets/images/logoslider/4.png";
import logo5 from "../../assets/images/logoslider/5.png";
import logo6 from "../../assets/images/logoslider/6.png";
import logo7 from "../../assets/images/logoslider/7.png";
import logo8 from "../../assets/images/logoslider/8.png";
import logo9 from "../../assets/images/logoslider/9.png";


const logos = [ logo1,logo2,logo3,logo5,logo6,logo7,logo8,logo9 ];

const HomeLogoSlider = () => {
  return (
    <div className="slider-container w-full border-t-2 border-b-2 border-orange-500 border-opacity-50 mt-20 mb-5">
      <div className="slider-track gap-8 p-2">
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`Logo ${index}`} className="h-40" />
        ))}
        {logos.map((logo, index) => (
          <img key={`copy-${index}`} src={logo} alt={`Logo Copy ${index}`} className="h-40" />
        ))}
      </div>
    </div>
  );
};

export default HomeLogoSlider;
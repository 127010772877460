import React from "react";
import AboutBanner from "../components/About/aboutBanner";
import AboutCreative from "../components/About/aboutCreative";
import AboutContent from "../components/About/aboutContent";
import EventGrid from "../components/About/aboutEvent";
import imageArray1 from "../assets/images/Events/1.jpg";
import imageArray2 from "../assets/images/Events/2.jpg";
import imageArray3 from "../assets/images/Events/3.jpg";
import imageArray4 from "../assets/images/Events/4.jpg";
import imageArray5 from "../assets/images/Events/5.jpg";
import imageArray6 from "../assets/images/Events/6.jpg";
import imageArray7 from "../assets/images/Events/7.jpg";
import imageArray8 from "../assets/images/Events/8.jpg";
import imageArray9 from "../assets/images/Events/9.jpg";
import imageArray10 from "../assets/images/Events/10.jpg";
import imageArray11 from "../assets/images/Events/11.jpg";
import imageArray12 from "../assets/images/Events/12.jpg";
import imageArray13 from "../assets/images/Events/13.jpg";
import imageArray14 from "../assets/images/Events/14.jpg";
import imageArray15 from "../assets/images/Events/15.jpg";
import imageArray16 from "../assets/images/Events/16.jpg";
import imageArray17 from "../assets/images/Events/17.jpg";
import imageArray18 from "../assets/images/Events/18.jpg";
import imageArray19 from "../assets/images/Events/19.jpg";
import imageArray20 from "../assets/images/Events/20.jpg";
import imageArray21 from "../assets/images/Events/21.jpg";
import imageArray22 from "../assets/images/Events/22.jpg";
import imageArray23 from "../assets/images/Events/23.jpg";
import imageArray24 from "../assets/images/Events/24.jpg";
import imageArray25 from "../assets/images/Events/25.jpg";

const AboutUs = () => {
  const imageArray = [
    { url: imageArray1, alt: "Image 1" },
    { url: imageArray2, alt: "Image 1" },
    { url: imageArray3, alt: "Image 1" },
    { url: imageArray4, alt: "Image 1" },
    { url: imageArray5, alt: "Image 1" },
    { url: imageArray6, alt: "Image 1" },
    { url: imageArray7, alt: "Image 1" },
    { url: imageArray8, alt: "Image 1" },
    { url: imageArray9, alt: "Image 1" },
    { url: imageArray10, alt: "Image 1" },
    { url: imageArray11, alt: "Image 1" },
    { url: imageArray12, alt: "Image 1" },
    { url: imageArray13, alt: "Image 1" },
    { url: imageArray14, alt: "Image 1" },
    { url: imageArray15, alt: "Image 1" },
    { url: imageArray16, alt: "Image 1" },
    { url: imageArray17, alt: "Image 1" },
    { url: imageArray18, alt: "Image 1" },
    { url: imageArray19, alt: "Image 1" },
    { url: imageArray20, alt: "Image 1" },
    { url: imageArray21, alt: "Image 1" },
    { url: imageArray22, alt: "Image 1" },
    { url: imageArray23, alt: "Image 1" },
    { url: imageArray24, alt: "Image 1" },
    { url: imageArray25, alt: "Image 1" },
  ];

  return (
    <div className="w-full">
      <AboutBanner />
      <AboutCreative />
      <AboutContent />
      <EventGrid heading="Our Events" images={imageArray} />
    </div>
  );
};

export default AboutUs;

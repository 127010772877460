import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Services from "./pages/services";
import AboutUs from "./pages/about";
import ContactUs from "./pages/contact";
import { ToastProvider } from './components/newToast'; 
import Pricing from "./pages/pricing";
// import FloatingIconBar from "./components/floatingIconBar";
import Security from "./pages/security";
import Package from "./pages/package";
import { CartProvider } from "./components/cartContext";
import Payment from "./pages/payment";
import Blog from "./pages/blog";
import BlogDetail from "./components/blogDetail";

function App() {
  return (
    <div className="bg-black">
      <Router>
      <CartProvider>
      <ToastProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/security" element={<Security />} />
          <Route path="/package" element={<Package />} />
          <Route path='/checkout' element={<Payment />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        {/* <FloatingIconBar /> */}
        <Footer />
        </ToastProvider>
      </CartProvider>
      </Router>
    </div>
  );
}

export default App;

import React, { useState } from "react";

const EventGrid = ({ heading, images }) => {
  const [visibleImages, setVisibleImages] = useState(10);
  const [expandedImage, setExpandedImage] = useState(null);

  const loadMoreImages = () => {
    setVisibleImages(images.length);
  };

  const expandImage = (imageIndex) => {
    setExpandedImage(imageIndex);
  };

  const closeImage = () => {
    setExpandedImage(null);
  };

  return (
    <div className="pb-20 px-4">
      <h2 className="text-5xl text-orange-500 font-bold mb-2 text-center">
        {heading}
      </h2>
      <div className="container mx-auto mt-5 flex">
        <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-4">
          {images.slice(0, visibleImages).map((image, index) => (
            <div key={index}>
              {expandedImage === index ? (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
                  <img
                    src={image.url}
                    alt={image.alt}
                    className="max-w-screen-xl max-h-screen p-4"
                  />
                  <button
                    onClick={closeImage}
                    className="absolute top-4 right-4 text-white bg-orange-500 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer"
                  >
                    X
                  </button>
                </div>
              ) : (
                <img
                  src={image.url}
                  alt={image.alt}
                  className="w-full h-auto cursor-pointer"
                  onClick={() => expandImage(index)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {visibleImages < images.length && (
        <button
          onClick={loadMoreImages}
          className="bg-orange-500 text-white px-4 py-2 mt-4 rounded hover:bg-orange-600 mx-auto block mt-12"
        >
          Show More
        </button>
      )}
    </div>
  );
};

export default EventGrid;

// import React, { useState } from "react";

// const EventGrid = ({ heading, images }) => {
//   const [visibleImages, setVisibleImages] = useState(10);

//   const loadMoreImages = () => {
//     setVisibleImages(images.length);
//   };

//   return (
//     <div className="pb-20 px-4">
//       <h2 className="text-5xl text-orange-500 font-bold mb-2 text-center">
//         {heading}
//       </h2>
//       <div className=" container mx-auto mt-5 flex">
//         <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-4">
//           {images.slice(0, visibleImages).map((image, index) => (
//             <div key={index}>
//               <img src={image.url} alt={image.alt} className="w-full h-auto" />
//             </div>
//           ))}
//         </div>
//       </div>
//       {visibleImages < images.length && (
//         <button
//           onClick={loadMoreImages}
//           className="bg-orange-500 text-white px-4 py-2 mt-4 rounded hover:bg-orange-600 mx-auto block mt-12 "
//         >
//           Show More
//         </button>
//       )}
//     </div>
//   );
// };

// export default EventGrid;

import React from "react";

const PaymentOptions = () => {
  const paymentOptions = [
    {
      name: "Credit Card",
      iconClass: "far fa-credit-card",
    },
    {
      name: "PayPal",
      iconClass: "fab fa-paypal",
    },
    {
      name: "Other",
      iconClass: "fas fa-dollar-sign",
    },
  ];

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-semibold mb-4">We Accept Payment By:</h1>
      <div className="flex items-center space-x-4">
        {paymentOptions.map((option, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-2"
          >
            <i className={`text-3xl ${option.iconClass}`}></i>
            <span className="text-xl">{option.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentOptions;

import React from "react";
import AllInOneImage from "../../assets/images/website-hosting-concept-with-bright-light.jpg";

const AboutContent = () => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-between p-4 space-y-4 md:space-x-4 container mx-auto mt-5 flex py-16">
      <div className="md:w-1/2">
        <img
          src={AllInOneImage}
          alt="IT Services"
          className="max-w-full h-auto"
        />
      </div>
      <div className="md:w-1/2 pr-5">
        <h2 className="text-2sm font-bold  text-orange-500 mb-3">What We Do</h2>
        <p className=" text-white text-5xl mb-5">
          We specialize in a wide range of services to meet your business needs
        </p>
        <p className="text-gray-500 ">
          Our core offerings include custom software development, web
          development, mobile app development, cloud computing, cybersecurity,
          and IT consultation. Whether you need a tailored software solution, an
          effective online presence, or strategic guidance for business growth,
          we've got you covered.
        </p>
      </div>
    </div>
  );
};

export default AboutContent;

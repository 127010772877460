import React from "react";

const PricingIntro = () => {
  return (
    <div className="pb-10 pt-10">
      <h2 className="text-xl font-semibold  text-center text-white">
        Our Pricing
      </h2>
      <p className="mt-4 text-gray-400 text-center text-5xl">
        No hidden charges. <br /> Choose your perfect plan.
      </p>
    </div>
  );
};

export default PricingIntro;

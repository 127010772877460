import React from "react";

const HomeContact = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-orange-500 p-10  mt-10 mb-10 shadow-md w-full mx-auto">
      <div className="md:w-2/3 mb-4 md:mb-0">
        <h2 className="text-2xl font-semibold text-gray-800">
          "Innovating the future of software with cutting-edge technology."
        </h2>
        <p className="mt-2 text-gray-700">- Your Trusted Software Partner</p>
      </div>
      <div className="md:w-1/3 text-center">
        <button className="bg-black text-white py-2 px-6 rounded-lg shadow-md ">
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default HomeContact;

import React from "react";
import Banner from "../../assets/images/security.jpg";

const SecurityBanner = () => {
  return (
    <div className="relative  h-96 overflow-hidden">
      <img
        src={Banner}
        alt="Service Banner"
        className="object-cover w-full h-full "
        style={{
          opacity: 0.6,
        }}
      />
      <div className="h-96 flex flex-col justify-center items-center absolute inset-x-0 bottom-0 text-center px-4">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
          Cyber Security
        </h1>
        <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-white leading-tight">
          Secure Your Digital World
        </h3>
        <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl text-white">
          We provide top-notch cybersecurity solutions to protect your data.
        </p>
      </div>

    </div>
  );
};

export default SecurityBanner;

import React from "react";
import HeadingPayment from "../components/Payment/headingPayment";
import PaymentMethod from "../components/Payment/paymentMethod";

const Payment = () => {
  return (
    <div className="w-full">
        <HeadingPayment />
        <PaymentMethod />
    </div>
  );
};

export default Payment;
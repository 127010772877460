import React from "react";
import Logo from "../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className=" border-t border-t-orange-500 text-white py-4 px-4">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-start">
        <div className="sm:flex">
          <div className="mt-4 sm:w-1/2 sm:pr-4">
            <h4 className="text-xl text-orange-500 font-semibold">
              Quick Links
            </h4>
            <ul className="mt-2">
              <li>
                <a href="/" className="hover:text-orange-500">
                  Home
                </a>
              </li>
              <li>
                <a href="/services" className="hover:text-orange-500">
                  Services
                </a>
              </li>
             
              <li>
                <a href="/about" className="hover:text-orange-500">
                  About Us
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:text-orange-500">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-4 sm:w-1/2 sm:pl-4">
            <h4 className="text-xl font-semibold text-orange-500">
              Contact Us
            </h4>
            <p className="mt-3">
              <a href="mailto:info@codesignservices.com" target="_blank" className="hover:text-orange-500">
                info@codesignservices.com
              </a>
            </p>
            <p>
              <a href="https://wa.me/+447380311066/?text=urlencodedtext" target="_blank" className="hover:text-orange-500">
              +447380311066
              </a>
            </p>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <p className="text-xl font-semibold text-orange-500">Socials</p>
          <div className="flex space-x-4 mt-2">
            <a
              href="https://www.facebook.com/codesignservices/"
              className="bg-blue-500 text-white rounded-full py-2 px-4 block text-center hover:bg-blue-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="lg" />
            </a>
            <a
              href="https://www.instagram.com/codesign_ltd/"
              className="bg-pink-500 text-white rounded-full py-2 px-4 block text-center hover:bg-pink-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a
              href="https://wa.me/+447380311066"
              className="bg-green-500 text-white rounded-full py-2 px-4 block text-center hover:bg-green-700"
              target="_blank"
            >
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            </a>
            <a
              href="https://www.linkedin.com/company/codesignservices/"
              className="bg-blue-800 text-white rounded-full py-2 px-4 block text-center hover:bg-blue-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
          </div>
        </div>
        <div className="mt-4 flex items-center">
          <a href="/" className='flex' >
            <img src={Logo} alt="Logo" className="h-14 w-12" />
            <span className="ml-2 text-xl font-semibold mt-2">CODESIGN</span>
          </a>
        </div>
      </div>
      <div className="mt-4 text-center">
        <p className="text-sm">Copyright © 2024, CODESIGN</p>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import HomeBgVideo from "../../assets/video/Ui Ux Change - Codesign.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const HomeAgency = () => {
  return (
    <section className="pt-10 md:pt-20 pb-10 md:pb-30 px-4">
      <div className="container mx-auto flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-6 md:mb-0">
          <video
            src={HomeBgVideo}
            alt="Agency Image"
            className="w-full"
            autoPlay
            loop
            muted
          />
        </div>

        <div className="md:w-1/2 md:pl-10">
          <h1 className="text-white text-5xl font-bold mt-5">
            We are a dynamic IT agency of creative people.
          </h1>
          <h1 className="text-2xl font-semibold mb-8 mt-8 text-orange-500">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> We are the
            perfect solution
          </h1>
          <p className="text-2xl text-white mb-6">
            We provide Digital IT services like web designing, 3D design &
            animation, cyber security, etc to every sort of business to help
            them build something great and exciting.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HomeAgency;

import React from 'react';

const HeadingPayment = () => {
  return (
    <div className="p-10">
     <div className='border-b-2 border-white'>
            <h1 className="text-2xl md:text-4xl pb-2 font-bold text-orange-500 text-center sm:text-left">CHECKOUT METHOD</h1>
      </div>
    </div>
  );
};

export default HeadingPayment;
import React from "react";
import ServiceBanner from "../components/Services/serviceBanner";
import ServiceProvider from "../components/Services/serviceProvider";
import "font-awesome/css/font-awesome.min.css";
import ServicePortfolio from "../components/Services/servicePorfolio";

const Services = () => {
  return (
    <div className="w-full">
      <ServiceBanner />
      <ServiceProvider />
      <ServicePortfolio />
    </div>
  );
};

export default Services;

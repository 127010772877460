import React from "react";
import HomeBgVideo from "../../assets/video/video.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const HomePortfolio = () => {
  return (
    <section className="pt-10 md:pt-20 pb-10 md:pb-30 px-4">
      <div className="container mx-auto flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-10">
          <h1 className="text-white text-5xl font-bold mt-5">
           Innovation at the Heart of Every Project
          </h1>
          <h1 className="text-2xl font-semibold mb-8 mt-8 text-orange-500">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> Your Partner in Digital Transformation
          </h1>
          <p className="text-2xl text-white mb-6">
           Our cutting-edge IT services, ranging from web development to cybersecurity, help businesses of all sizes to evolve, stay competitive, and achieve their goals in today's digital world.
           With a strong focus on creativity and technical excellence, we deliver solutions that drive success.
          </p>
        </div>      
        <div className="md:w-1/2 mb-6 md:mb-0">
          <video
            src={HomeBgVideo}
            alt="Agency Image"
            className="w-full"
            autoPlay
            loop
            muted
          />
        </div>
      </div>
    </section>
  );
};

export default HomePortfolio;

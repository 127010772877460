import React from "react";
import PackageBanner from "../components/Package/packageBanner";
import PackageProvider from "../components/Package/packageProvider";

const Package = () => {
  return (
    <div className="w-full">
      <PackageBanner />
      <PackageProvider /> 
    </div>
  );
};

export default Package;

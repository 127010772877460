import React from "react";
import BlogBanner from "../components/Blog/blogBanner";
import BlogSection from "../components/Blog/blogBoxes";

const Blog = () => {
  return (
    <div className="w-full"> 
      <BlogBanner />
      <BlogSection />
    </div>
  );
};

export default Blog;

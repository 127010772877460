import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const PricingShow = () => {
  const [showPricing, setShowPricing] = useState(false);
  const [apiData, setApiData] = useState([]);

  const apiUrl = process.env.REACT_APP_PRICE_URL;

  useEffect(() => {
    if (apiUrl) {
      axios
        .get(apiUrl)
        .then((response) => {
          setApiData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data from the API:", error);
        });
    }

    setTimeout(() => {
      setShowPricing(true);
    }, 1000);
  }, [apiUrl]);

  console.log(apiData);

  return (
    <div className="py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {apiData.map((item, index) => (
            <div
              key={apiData.id}
              className={`p-6 border border-orange-500 rounded-lg text-center ${
                showPricing
                  ? "transition-opacity duration-500 transform translate-x-0 opacity-100 hover:shadow-md"
                  : "opacity-0 translate-x-16"
              }`}
              style={{
                transitionDelay: `${index * 0.6}s`,
              }}
            >
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-white">
                  <span className="flex items-center justify-center">
                    <img
                      src={item.icon}
                      alt="Service Icon"
                      className="w-6 h-6 text-orange-500 mr-2"
                    />
                    {item.heading}
                  </span>
                </h3>

                <p className="text-gray-400 mt-2">{item.slogan}</p>
                <p className="text-3xl text-white mt-4">
                  ${item.pricing}/month
                </p>
                <div className="mt-6">
                  {item.features.map((feature, featureIndex) => (
                    <div
                      key={featureIndex}
                      className="flex items-center text-gray-400"
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-orange-500 mr-2"
                      />
                      {feature.value}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingShow;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import axios from "axios";
// import {
//   faBullhorn,
//   faImage,
//   faCube,
//   faCode,
//   faShieldAlt,
//   faMobile,
//   faNetworkWired,
//   faCheckCircle,
// } from "@fortawesome/free-solid-svg-icons";

// const PricingShow = () => {
//   const [showPricing, setShowPricing] = useState(false);
//   const [apiData, setApiData] = useState([]);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_PRICE_URL)
//       .then((response) => {
//         setApiData(response.data.data);
//         setShowPricing(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   console.log(showPricing);

//   return (
//     <div className="py-8">
//       <div className="container mx-auto px-4">
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {apiData.map((item, index) => (
//             <div
//               key={index}
//               className={`p-6 border border-orange-500 rounded-lg text-center ${
//                 showPricing
//                   ? "transition-opacity duration-500 transform transition translate-x-0 opacity-100 hover:shadow-md"
//                   : "opacity-0 translate-x-16"
//               }`}
//               style={{
//                 transitionDelay: `${index * 0.6}s`,
//               }}
//             >
//               <div className="p-6">
//                 <h3 className="text-2xl font-semibold text-white">
//                   <span className="flex items-center justify-center">
//                     <img
//                       src={item.icon}
//                       alt="Service Icon"
//                       className="w-6 h-6 text-orange-500 mr-2"
//                     />
//                     {item.heading}
//                   </span>
//                 </h3>

//                 <p className="text-gray-400 mt-2">{item.slogan}</p>
//                 <p className="text-3xl text-white mt-4">
//                   ${item.pricing}/month
//                 </p>
//                 <div className="mt-6">
//                   {item.features.map((feature, featureIndex) => (
//                     <div
//                       key={featureIndex}
//                       className="flex items-center text-gray-400"
//                     >
//                       <FontAwesomeIcon
//                         icon={faCheckCircle}
//                         className="text-orange-500 mr-2"
//                       />
//                       {feature.value}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PricingShow;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import axios from "axios";
// import {
//   faBullhorn,
//   faImage,
//   faCube,
//   faCode,
//   faShieldAlt,
//   faMobile,
//   faNetworkWired,
//   faCheckCircle,
// } from "@fortawesome/free-solid-svg-icons";

// const PricingShow = () => {
//   const [showPricing, setShowPricing] = useState(false);
//   const [apiData, setApiData] = useState([]);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_PRICE_URL)
//       .then((response) => {
//         setApiData(response.data.data);
//         setShowPricing(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   console.log(showPricing);

//   return (
//     <div className="py-8">
//       <div className="container mx-auto px-4">
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {apiData.map((item) => (

//             <div
//               key={item.id}
//               className={`p-6 border border-orange-500 rounded-lg text-center ${
//                 showPricing
//                   ? "transition-opacity duration-500 transform transition translate-x-0 opacity-100 hover:shadow-md"
//                   : "opacity-0 translate-x-16"
//               }`}
//               style={{
//                 transitionDelay: `${item.id * 0.6}s`,
//               }}
//             >
//               <div className="p-6">
//                 <h3 className="text-2xl font-semibold text-white">
//                   <span className="flex items-center justify-center">
//                     <img
//                       src={item.icon}
//                       alt="Service Icon"
//                       className="w-6 h-6 text-orange-500 mr-2"
//                     />
//                     {item.heading}
//                   </span>
//                 </h3>

//                 <p className="text-gray-400 mt-2">{item.slogan}</p>
//                 <p className="text-3xl text-white mt-4">
//                   ${item.pricing}/month
//                 </p>
//                 <div className="mt-6">
//                   {item.features.map((feature, featureIndex) => (
//                     <div
//                       key={featureIndex}
//                       className="flex items-center text-gray-400"
//                     >
//                       <FontAwesomeIcon
//                         icon={faCheckCircle}
//                         className="text-orange-500 mr-2"
//                       />
//                       {feature.value}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PricingShow;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faBullhorn,
//   faImage,
//   faCube,
//   faCode,
//   faShieldAlt,
//   faMobile,
//   faNetworkWired,
//   faCheckCircle,
// } from "@fortawesome/free-solid-svg-icons";

// const pricingData = [
//   {
//     title: "Digital Marketing",
//     icon: faBullhorn,
//     price: "$100/month",
//     description:
//       "Boost your online presence with our digital marketing services.",
//     features: [
//       "Social Media Marketing",
//       "Search Engine Optimization",
//       "Content Marketing",
//     ],
//   },
//   {
//     title: "2D Design & Animation",
//     icon: faImage,
//     price: "$200/hour",
//     description: "Create stunning 2D designs and animations for your projects.",
//     features: [
//       "Custom 2D Design",
//       "Animation Production",
//       "Illustration Services",
//     ],
//   },
//   {
//     title: "3D Design & Animation",
//     icon: faCube,
//     price: "$250/hour",
//     description:
//       "Bring your ideas to life with our 3D design and animation services.",
//     features: ["3D Modeling", "Character Animation", "Visual Effects"],
//   },
//   {
//     title: "Web Development",
//     icon: faCode,
//     price: "$1500/website",
//     description: "Build custom websites tailored to your business needs.",
//     features: [
//       "Responsive Design",
//       "E-commerce Integration",
//       "Custom Web Apps",
//     ],
//   },
//   {
//     title: "Mobile App Development",
//     icon: faMobile,
//     price: "$2000/app",
//     description: "Develop mobile apps for iOS and Android platforms.",
//     features: [
//       "Cross-Platform Development",
//       "User Interface Design",
//       "App Testing and Optimization",
//     ],
//   },
//   {
//     title: "Blockchain Development",
//     icon: faNetworkWired,
//     price: "$3000/project",
//     description: "Create secure and decentralized blockchain applications.",
//     features: [
//       "Smart Contract Development",
//       "Cryptocurrency Integration",
//       "Blockchain Security",
//     ],
//   },
//   {
//     title: "Cyber Security",
//     icon: faShieldAlt,
//     price: "$500/month",
//     description:
//       "Protect your data and assets with our cyber security services.",
//     features: ["Network Security", "Threat Detection", "Incident Response"],
//   },
// ];

// const PricingShow = () => {
//   const [showPricing, setShowPricing] = useState(false);

//   useEffect(() => {
//     setShowPricing(true);
//   }, []);

//   return (
//     <div className="py-8">
//       <div className="container mx-auto px-4">
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {pricingData.map((item, index) => (
//             <div
//               key={index}
//               className={`p-6 border border-orange-500 rounded-lg text-center ${
//                 showPricing
//                   ? "transition-opacity duration-500 transform transition translate-x-0 opacity-100 hover:shadow-md"
//                   : "opacity-0 translate-x-16"
//               }`}
//               style={{
//                 transitionDelay: `${index * 0.6}s`,
//               }}
//             >
//               <div className="p-6">
//                 <h3 className="text-2xl font-semibold text-white">
//                   <FontAwesomeIcon
//                     icon={item.icon}
//                     className="text-orange-500 mr-2"
//                   />
//                   {item.title}
//                 </h3>
//                 <p className="text-gray-400 mt-2">{item.description}</p>
//                 <p className="text-3xl text-white mt-4">{item.price}</p>
//                 <div className="mt-6">
//                   {item.features.map((feature, featureIndex) => (
//                     <div
//                       key={featureIndex}
//                       className="flex items-center text-gray-400"
//                     >
//                       <FontAwesomeIcon
//                         icon={faCheckCircle}
//                         className="text-orange-500 mr-2"
//                       />
//                       {feature}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PricingShow;

import React from "react";
import Banner from "../../assets/images/glass-jar-full-money-front-decreasing-stacked-coins-against-white-background.jpg";

const PricingBanner = () => {
  return (
    <div className="relative h-96 overflow-hidden">
      <div
        className="absolute inset-x-0 bottom-0 mt-20"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.8,
          height: "170%",
        }}
      ></div>
      <div className="h-96 flex flex-col justify-center items-center absolute inset-x-0 bottom-0">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
          Our pricing
        </h1>
        <h2 className="text-lg sm:text-2xl md:text-3xl font-semibold text-white">
          Pricing Package
        </h2>
      </div>
    </div>
  );
};

export default PricingBanner;

// import React from "react";
// import Banner from "../../assets/images/glass-jar-full-money-front-decreasing-stacked-coins-against-white-background.jpg";

// const PricingBanner = () => {
//   return (
//     <div className="relative  h-96 overflow-hidden">
//       <img
//         src={Banner}
//         alt="Service Banner"
//         className="object-cover w-full h-full -mt-20 "
//         style={{
//           opacity: 0.8,
//         }}
//       />
//       <div className="h-96 flex flex-col justify-center items-center absolute inset-x-0 bottom-0">
//         <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
//           Our pricing
//         </h1>
//         <h2 className="text-lg sm:text-2xl md:text-3xl font-semibold text-white">
//           Pricing Package
//         </h2>
//       </div>
//     </div>
//   );
// };

// export default PricingBanner;

// import React from "react";
// import Banner from "../../assets/images/glass-jar-full-money-front-decreasing-stacked-coins-against-white-background.jpg";

// const PricingBanner = () => {
//   return (
//     <div className="relative max-w-full h-96 sm:h-80 md:h-96 lg:h-112 xl:h-128 2xl:h-144">
//       <img
//         src={Banner}
//         className="bg-cover bg-center absolute inset-0 h-96 w-full"
//         style={{
//           opacity: 0.8,
//         }}
//       />
//       <div className="h-full flex flex-col justify-center items-center relative">
//         <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
//           Our pricing
//         </h1>
//         <h2 className="text-lg sm:text-2xl md:text-3xl font-semibold text-white">
//           Pricing Package
//         </h2>
//       </div>
//     </div>
//   );
// };

// export default PricingBanner;

// import React from "react";
// import Banner from "../../assets/images/glass-jar-full-money-front-decreasing-stacked-coins-against-white-background.jpg";

// const PricingBanner = () => {
//   return (
//     <div className="relative max-w-full h-96 sm:h-80 md:h-96 lg:h-112 xl:h-128 2xl:h-144">
//       <img
//         src={Banner}
//         className="bg-cover bg-center absolute inset-0 h-96 w-full"
//         style={{
//           opacity: 0.8,
//         }}
//       />
//       <div className="h-96 flex flex-col justify-center items-center relative">
//         <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
//           Our pricing
//         </h1>
//         <h2 className="text-lg sm:text-2xl md:text-3xl font-semibold text-white">
//           Pricing Package
//         </h2>
//       </div>
//     </div>
//   );
// };

// export default PricingBanner;

// import React from "react";
// import Banner from "../../assets/images/pricing.jpg";

// const PricingBanner = () => {
//   return (
//     <div className="relative h-96 w-full">
//       {/* <div
//         className="bg-cover bg-center absolute inset-0"
//         style={{
//           backgroundImage: `url(${Banner})`,
//           opacity: 0.8,
//         }}
//       ></div> */}
//       <div
//         className="bg-cover bg-center bg-no-repeat absolute inset-0"
//         style={{
//           backgroundImage: `url(${Banner})`,
//           opacity: 0.8,
//           backgroundSize: "cover",
//         }}
//       ></div>
//       <div className="h-96 flex flex-col justify-center items-center relative">
//         <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
//           Our pricing
//         </h1>
//         <h2 className="text-lg sm:text-2xl md:text-3xl font-semibold text-white">
//           Pricing Package
//         </h2>
//       </div>
//     </div>
//   );
// };

// export default PricingBanner;

import React from "react";
import HomeBanner from "../components/Home/banner";
import HomeIntroduction from "../components/Home/homeIntroduction";
import HomeAgency from "../components/Home/homeAgency";
import HomeTestimonial from "../components/Home/homeTestimonial";
import HomeLogoSlider from "../components/Home/homeLogoSlider";
import HomePortfolio from "../components/Home/homePortfolio";
import PackageProvider from "../components/Package/packageProvider";
import HomeContact from "../components/Home/homeConatct";

const Home = () => {
  return (
    // <div className="w-full bg-gray-900">
    <div className="w-full"> 
      <HomeBanner />
      <HomeIntroduction />
      <HomePortfolio />
      <HomeAgency />
      <PackageProvider />
      <HomeContact />
      <HomeLogoSlider />
      <HomeTestimonial />
    </div>
  );
};

export default Home;

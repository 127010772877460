import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faLaptop, faRocket } from "@fortawesome/free-solid-svg-icons";

const HomeIntroduction = () => {
  const contentArray = [
    {
      heading: "Who are we?",
      text: "We are a forward-thinking software agency committed to delivering innovative and top-tier technological solutions. Our team is driven by a passion for continuous improvement and success, always striving to exceed expectations and set new standards in the software industry.",
      icon: faUsers,
    },
    {
      heading: "What do we do?",
      text: "We specialize in offering comprehensive Digital IT services and cutting-edge IT solutions tailored to the unique needs of businesses across all industries. From startups to established enterprises, we empower companies with technology that drives efficiency, growth, and digital transformation.",
      icon: faLaptop,
    },
    {
      heading: "What's our mission?",
      text: "Our mission is to provide unmatched quality and excellence in everything we do, ensuring that our clients receive the best possible outcomes. We are dedicated to building long-term partnerships by delivering results that consistently meet and exceed client expectations.",
      icon: faRocket,
    },
  ];

  const [currentIntro, setCurrentIntro] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIntro((prev) => (prev + 1) % contentArray.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [contentArray.length]);

  const visibleIntro = contentArray.slice(currentIntro, currentIntro + 1);

  return (
    <div className="pt-10 pb-10 ">
      <div className="container mx-auto text-center lg:text-left">
        <h1 className="text-5xl font-bold mb-8 text-white text-center">
          We work with businesses and assist <br />
          them to overcome their challenges.
        </h1>
      </div>
      <div className="container mx-auto mt-5 flex flex-col items-center justify-center lg:flex-row lg:justify-center">
        {visibleIntro.map((content, index) => (
          <div
            key={index}
            className="p-10  shadow-lg rounded-lg border border-orange-500  text-center max-w-lg"
          >
          
            <div className="flex flex-col items-center justify-center mb-6">
              <FontAwesomeIcon
                icon={content.icon}
                className="text-5xl mb-4 text-orange-500"
              />
              <h2 className="text-4xl font-bold text-white">
                {content.heading}
              </h2>
            </div>
           


            <p className="text-lg text-gray-700 leading-relaxed">
              {content.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeIntroduction;



// import React, {useEffect, useState}from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUsers, faLaptop, faRocket } from "@fortawesome/free-solid-svg-icons";

// const HomeIntroduction = () => {
//   const contentArray = [
//     {
//       heading: "Who are we?",
//       text: "We are a forward-thinking software agency committed to delivering innovative and top-tier technological solutions. Our team is driven by a passion for continuous improvement and success, always striving to exceed expectations and set new standards in the software industry.",
//       icon: faUsers,
//     },
//     {
//       heading: "What do we do?",
//       text: "We specialize in offering comprehensive Digital IT services and cutting-edge IT solutions tailored to the unique needs of businesses across all industries. From startups to established enterprises, we empower companies with technology that drives efficiency, growth, and digital transformation.",
//       icon: faLaptop,
//     },
//     {
//       heading: "What's our mission?",
//       text: "Our mission is to provide unmatched quality and excellence in everything we do, ensuring that our clients receive the best possible outcomes. We are dedicated to building long-term partnerships by delivering results that consistently meet and exceed client expectations.",
//       icon: faRocket,
//     },
//   ];

//   const [currentIntro, setCurrentIntro] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIntro((prev) => (prev + 1) % contentArray.length);
//     }, 2000);

//     return () => clearInterval(interval);
//   }, []);

//   const visibleIntro = contentArray.slice(
//     currentIntro,
//      currentIntro + 1
//   );

//   return (
//     <div className="pt-10 pb-10">
//       <div className="container mx-auto mt-5 text-center lg:text-left">
//         <h1 className="text-5xl font-bold mb-4 text-white text-center">
//           We work with businesses and assist <br />
//           them to overcome their challenges.
//         </h1>
//       </div>
//       <div className="container mx-auto mt-5 flex flex-col lg:flex-row">
//         {visibleIntro.map((contentArray) => (
//           <div key={contentArray} className=" p-6 border border-orange-500 rounded-lg text-center  mt-4">
//             <div className="flex items-center text-center">
//               <FontAwesomeIcon
//                 icon={contentArray.icon}
//                 className="text-3xl pr-4 text-orange-500"
//               />
//               <h2 className="text-3xl font-bold mb-2 text-orange-500">
//                 {contentArray.heading}
//               </h2>
//             </div>
//             <div className="flex items-center text-center text-gray-500 text-lg">
//               <p>{contentArray.text}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default HomeIntroduction;

// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUsers, faLaptop, faRocket } from "@fortawesome/free-solid-svg-icons";

// const HomeIntroduction = () => {
//   const contentArray = [
//     {
//       heading: "Who are we?",
//       text: "We are a modern software agency that aspires for greatness.",
//       icon: faUsers,
//     },
//     {
//       heading: "What do we do?",
//       text: "We provide Digital IT services and IT solutions to every sort of company.",
//       icon: faLaptop,
//     },
//     {
//       heading: "What's our mission?",
//       text: "Provide quality and excellence to our clients.",
//       icon: faRocket,
//     },
//   ];

//   return (
//     <div className="pt-20 pb-20">
//       <div className="container mx-auto mt-5 flex">
//         <h1 className="text-5xl font-bold mb-4 text-white">
//           We work with businesses and assist <br />
//           them to overcome their challenges.
//         </h1>
//       </div>
//       <div className="container mx-auto mt-5 flex">
//         <div className=" text-white flex mt-5">
//           {contentArray.map((item, index) => (
//             <div key={index} className="flex-1 ">
//               <div className="flex items-center ">
//                 <FontAwesomeIcon
//                   icon={item.icon}
//                   className="text-3xl pr-4 text-orange-500"
//                 />
//                 <h2 className="text-3xl font-bold mb-2 text-orange-500">
//                   {item.heading}
//                 </h2>
//               </div>
//               <div className="flex items-center text-gray-500 text-lg">
//                 <p>{item.text}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeIntroduction;

import React, { useState, useEffect } from "react";

const testimonials = [
  {
    id: 1,
    name: "— Sarah Khan, CEO at Nexus Retail",
    text: "CODESIGN completely transformed our online presence. Our new website is visually stunning, user-friendly, and optimized for conversions. Their team was responsive and delivered everything on time.",
  },
  {
    id: 2,
    name: "— David Patel, Marketing Director at Zenith Technologies",
    text: "The design team at CODESIGN brought our brand to life! Everything is consistent and beautifully crafted, from our logo to our marketing materials. We get compliments on our branding all the time!",
  },
  {
    id: 3,
    name: "— Emma Foster, Product Manager at InnoTech",
    text: "We needed high-quality animations for our product launch, and CODESIGN delivered beyond our expectations. Their creative team is incredibly talented and easy to work with. The animations elevated our campaign in a big way!",
  },
  {
    id: 4,
    name: "— Ahmed Saeed, IT Manager at Global Enterprises",
    text: "Our company’s data security was a major concern, but CODESIGN provided us with a comprehensive cyber security strategy that has kept us secure ever since. Their team is knowledgeable, proactive, and always available when we need support.",
  },
  {
    id: 5,
    name: "— Ayesha Malik, Founder of EatSmart App",
    text:"CODESIGN developed a custom mobile app for our business, and it has been a game-changer! The app is sleek, easy to navigate, and packed with needed features. Their development team is highly skilled and attentive to details.",
  },
  {
    id: 6,
    name: "— William Adams, CTO at NovaSoft",
    text: "CODESIGN helped streamline our development processes and implement a reliable DevOps pipeline. Their expertise in automation and cloud solutions improved our efficiency significantly. We couldn’t be happier with the results.",
  },
];

const TestimonialSlider = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const visibleTestimonials = testimonials.slice(
    currentTestimonial,
    currentTestimonial + 2
  );

  return (
    <div className="container mx-auto pb-20 pt-20 px-4">
      <h1 className="text-3xl font-bold mb-4 text-orange-500 ">
        Clients testimonial
      </h1>
      <p className="text-5xl font-semibold mb-4 text-white">
        Check what's our clients say about us
      </p>
      <div className="flex flex-col md:flex-row justify-center">
        {visibleTestimonials.map((testimonial) => (
          <div key={testimonial.id} className="p-6 border border-orange-500 rounded-lg text-center  mx-4 my-2 md:w-1/2">
            <p className="text-gray-500 text-lg">{testimonial.text}</p>
            <h2 className="text-2xl font-semibold text-orange-500">
              {testimonial.name}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;

// import React, { useState, useEffect } from "react";

// const testimonials = [
//   {
//     id: 1,
//     name: "John Doe",
//     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     text: "Aenean laoreet, ex at eleifend cursus, purus quam vulputate elit, non dictum nunc justo ut urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 3,
//     name: "John D",
//     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 4,
//     name: "Jane S",
//     text: "Aenean laoreet, ex at eleifend cursus, purus quam vulputate elit, non dictum nunc justo ut urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 5,
//     name: "John Do",
//     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
//   {
//     id: 6,
//     name: "Jane Smi",
//     text: "Aenean laoreet, ex at eleifend cursus, purus quam vulputate elit, non dictum nunc justo ut urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et mauris a massa tristique euismod in a purus.",
//   },
// ];

// const TestimonialSlider = () => {
//   const [currentTestimonial, setCurrentTestimonial] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, []);

//   const visibleTestimonials = testimonials.slice(
//     currentTestimonial,
//     currentTestimonial + 2
//   );

//   return (
//     <div className="container mx-auto pb-20 pt-20">
//       <h1 className="text-xl font-semibold mb-4 text-orange-500">
//         Clients testimonial
//       </h1>
//       <p className="text-5xl font-semibold mb-4 text-white">
//         Check what's our clients say about us
//       </p>
//       <div className="flex justify-center">
//         {visibleTestimonials.map((testimonial) => (
//           <div key={testimonial.id} className=" p-4  mx-4">
//             <p className="text-gray-500 text-xl">{testimonial.text}</p>
//             <h2 className="text-3xl font-semibold text-orange-500">
//               {testimonial.name}
//             </h2>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TestimonialSlider;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogBanner from "./Blog/blogBanner";

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://source.codesignservices.com/blogs/${id}`);
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };
    fetchBlog();
  }, [id]);

  return (
    <div>
        <BlogBanner />
        <div className="max-w-6xl mx-auto py-8 px-4">
        {blog ? (
            <>
            <p className="text-gray-700 mb-4 mt-6"><span className="text-white text-lg font-semibold">Published Date:</span> {blog.published_at.split('T')[0]}</p>
            {/* <h2 className="text-5xl text-white font-bold mb-6">{blog.Title}</h2> */}
            <h2 className="text-5xl text-white font-bold mb-6 underline decoration-orange-500">{blog.Title}</h2>
            <p className="text-gray-700">{blog.Content}</p>
            </>
        ) : (
            <p className="text-white">Loading...</p>
        )}
        </div>
    </div>
  );
};

export default BlogDetail;




// import React from "react";
// import { useParams } from "react-router-dom";

// const BlogDetail = () => {
//   const { id } = useParams();
//   const blogData = {
//     1: "This is the full content of the first blog post.",
//     2: "This is the full content of the second blog post.",
//     3: "This is the full content of the third blog post."
//   };

//   return (
//     <div className="max-w-4xl mx-auto py-8 px-4 p-20">
//       <h2 className="text-3xl text-white font-bold mb-6">Blog Detail</h2>
//       <p className="text-gray-700">{blogData[id]}</p>
//     </div>
//   );
// };

// export default BlogDetail;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation , useNavigate} from 'react-router-dom';
import md5 from 'crypto-js/md5';
import { v4 as uuidv4 } from 'uuid';  

const PaymentMethod = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const savedTotalAmount = parseFloat(localStorage.getItem('totalAmount')) || 0;
  const { cartItems = savedCartItems, totalAmount = savedTotalAmount } = location.state || {};
  const [convertedAmount, setConvertedAmount] = useState(totalAmount);  
  const [conversionRate, setConversionRate] = useState(1);  

  const apiID = process.env.REACT_APP_INSTITUTION_ID;
  const apiKey = process.env.REACT_APP_KUICKPAY_SECURED_KEY;
  const apiToken = process.env.REACT_APP_AUTH_TOKEN_URL;
  const apiCheckout = process.env.REACT_APP_CHECKOUT_URL;
  const apiSuccess = process.env.REACT_APP_SUCCESS_URL;
  const apiFailure = process.env.REACT_APP_FAILURE_URL;
  const apiExchange = process.env.REACT_APP_EXCHANGE_URL;
  const apiName = process.env.REACT_APP_MERCHANT_NAME;

  useEffect(() => {
    if (cartItems.length > 0 && totalAmount > 0) {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      localStorage.setItem('totalAmount', totalAmount.toString());
    }
  }, [cartItems, totalAmount]);

  const fetchConversionRate = async () => {
    try {
      const response = await axios.get(`${apiExchange}`);
      const rate = response.data.rates.PKR;
      setConversionRate(rate);
      setConvertedAmount(totalAmount * rate);  
    } catch (error) {
      console.error('Error fetching conversion rate:', error);
    }
  };

  useEffect(() => {
    fetchConversionRate();
  }, [totalAmount]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchAuthToken = async () => {
    try {
      const response = await axios.post(`${apiToken}`, {
        institutionID: apiID,
        kuickpaySecuredKey: apiKey,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data.auth_token;
    } catch (error) {
      console.error('Error fetching auth token:', error);
      return null;
    }
  };

  const createSignature = (formData, convertedAmount, uniqueOrderId) => {
    const signatureString = `${apiID}${uniqueOrderId}${convertedAmount}${apiKey}`;
    return md5(signatureString).toString();
  };

  const initiatePayment = async (formData, cartItems, convertedAmount) => {
    const authToken = await fetchAuthToken();
    if (!authToken) return;

    const uniqueOrderId = uuidv4();  

    const paymentData = {
      InstitutionID: apiID,
      OrderID: uniqueOrderId,
      MerchantName: apiName,
      Amount: convertedAmount,  
      TransactionDescription: 'Your transaction description',
      CustomerMobileNumber: formData.phone,
      CustomerEmail: formData.email,
      SuccessUrl: apiSuccess,  
      FailureUrl: apiFailure,  
      CheckoutUrl: apiCheckout,  
      OrderDate: new Date().toISOString().split('T')[0],
      Token: authToken,
      Signature: createSignature(formData, convertedAmount, uniqueOrderId), 
    };

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${apiCheckout}api/Redirection`;

    Object.keys(paymentData).forEach((key) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = paymentData[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();

    localStorage.removeItem('cartItems');
    localStorage.removeItem('totalAmount');
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (totalAmount <= 0) {
      alert('Please select a package and checkout before proceeding to payment.');
      return; 
    }

    await initiatePayment(formData, cartItems, convertedAmount);  
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="flex flex-col">
          <label htmlFor="firstName" className="mb-2 text-orange-500 font-semibold">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="lastName" className="mb-2 text-orange-500 font-semibold">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="phone" className="mb-2 text-orange-500 font-semibold">Phone</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="email" className="mb-2 text-orange-500 font-semibold">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="md:col-span-2 mt-6">
          <h3 className="text-xl text-orange-500 font-bold mb-4">Your Order</h3>
          <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
            <ul className="mb-4">
              {cartItems.map((item) => (
                <li key={item.id} className="flex justify-between mb-2">
                  <span>{item.package_name} (x{item.quantity})</span>
                  <span>$ {item.package_amount * item.quantity}</span> 
                </li>
              ))}
            </ul>
            
            <div className="flex justify-between font-semibold text-lg">
              <span>Total</span>
              <span>$ {totalAmount.toFixed(2)}</span> 
            </div>
          </div>
        </div>
        <div className="md:col-span-2">
          <button
            type="submit"
            className="w-full bg-orange-500 text-white py-3 rounded-lg font-semibold">
            Place Order & Proceed to Payment
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentMethod;







// import React, { useState , useEffect } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import md5 from 'crypto-js/md5';
// import { v4 as uuidv4 } from 'uuid';  

// const PaymentMethod = () => {
//   const location = useLocation();
//   // const { cartItems = [], totalAmount = 0 } = location.state || {};

//   const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
//   const savedTotalAmount = parseFloat(localStorage.getItem('totalAmount')) || 0;
//   // const totalAmount = cartItems.reduce((total, item) => total + item.package_amount * item.quantity, 0);
  
//   const { cartItems = savedCartItems, totalAmount = savedTotalAmount } = location.state || {};

//   useEffect(() => {
//     if (cartItems.length > 0 && totalAmount > 0) {
//       localStorage.setItem('cartItems', JSON.stringify(cartItems));
//       localStorage.setItem('totalAmount', totalAmount.toString());
//     }
//   }, [cartItems, totalAmount]);


//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     companyName: '',
//     country: '',
//     city: '',
//     state: '',
//     zip: '',
//     phone: '',
//     email: '',
//   });

  

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const fetchAuthToken = async () => {
//     try {
//       const response = await axios.post('https://testcheckout.kuickpay.com/api/KPToken', {
//         institutionID: '01234',
//         kuickpaySecuredKey: 'xWX+A8qbYkLgHf3e/pu6PZiycOGc0C/YXOr3XislvxI=',
//       }, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       return response.data.auth_token;
//     } catch (error) {
//       console.error('Error fetching auth token:', error);
//       return null;
//     }
//   };

//   const createSignature = (formData, totalAmount, uniqueOrderId) => {
//     const signatureString = `${'01234'}${uniqueOrderId}${totalAmount}${'xWX+A8qbYkLgHf3e/pu6PZiycOGc0C/YXOr3XislvxI='}`;
//     return md5(signatureString).toString();
//   };

//   const initiatePayment = async (formData, cartItems, totalAmount) => {
//     const authToken = await fetchAuthToken();

//     if (!authToken) {
//       console.error('No auth token available.');
//       return;
//     }

//     const uniqueOrderId = uuidv4();  

//     const paymentData = {
//       InstitutionID: '01234',
//       OrderID: uniqueOrderId,  
//       MerchantName: 'YourMerchantName',
//       Amount: totalAmount,
//       TransactionDescription: 'Your transaction description',
//       CustomerMobileNumber: formData.phone,
//       CustomerEmail: formData.email,
//       SuccessUrl: 'https://testcheckout.kuickpay.com/',  
//       FailureUrl: 'https://www.md5hashgenerator.com/',  
//       CheckoutUrl: 'https://testcheckout.kuickpay.com/',  
//       OrderDate: new Date().toISOString().split('T')[0],
//       Token: authToken,
//       Signature: createSignature(formData, totalAmount, uniqueOrderId),
//     };

    
//     const form = document.createElement('form');
//     form.method = 'POST';
//     form.action = 'https://testcheckout.kuickpay.com/api/Redirection';

//     Object.keys(paymentData).forEach((key) => {
//       const input = document.createElement('input');
//       input.type = 'hidden';
//       input.name = key;
//       input.value = paymentData[key];
//       form.appendChild(input);
//     });

//     document.body.appendChild(form);
//     form.submit();
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await initiatePayment(formData, cartItems, totalAmount);
//   };

//   return (
//     <div className="max-w-4xl mx-auto p-8">
//       <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6 md:grid-cols-2">
       
//         <div className="flex flex-col">
//           <label htmlFor="firstName" className="mb-2 text-orange-500 font-semibold">First Name</label>
//           <input
//             type="text"
//             id="firstName"
//             name="firstName"
//             value={formData.firstName}
//             onChange={handleChange}
//             className="p-2 border border-gray-300 rounded"
//             required
//           />
//         </div>
       
//         <div className="flex flex-col">
//           <label htmlFor="lastName" className="mb-2 text-orange-500 font-semibold">Last Name</label>
//           <input
//             type="text"
//             id="lastName"
//             name="lastName"
//             value={formData.lastName}
//             onChange={handleChange}
//             className="p-2 border border-gray-300 rounded"
//             required
//           />
//         </div>
//         <div className="flex flex-col">
//           <label htmlFor="phone" className="mb-2 text-orange-500 font-semibold">Phone</label>
//           <input
//             type="text"
//             id="phone"
//             name="phone"
//             value={formData.phone}
//             onChange={handleChange}
//             className="p-2 border border-gray-300 rounded"
//             required
//           />
//         </div>
//         <div className="flex flex-col">
//           <label htmlFor="email" className="mb-2 text-orange-500 font-semibold">Email</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className="p-2 border border-gray-300 rounded"
//             required
//           />
//         </div>
        
//         <div className="md:col-span-2 mt-6">
//           <h3 className="text-xl text-orange-500 font-bold mb-4">Your Order</h3>
//           <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
//             <ul className="mb-4">
//               {cartItems.map((item) => (
//                 <li key={item.id} className="flex justify-between mb-2">
//                   <span>{item.package_name} (x{item.quantity})</span>
//                   <span>$ {item.package_amount * item.quantity}</span>
//                 </li>
//               ))}
//             </ul>
//             <div className="flex justify-between font-semibold text-lg">
//               <span>Total</span>
//               <span>${totalAmount.toFixed(2)}</span>
//             </div>
//           </div>
//         </div>
//         <div className="md:col-span-2">
//           <button
//             type="submit"
//             className="w-full bg-orange-500 text-white py-3 rounded-lg font-semibold">
//             Place Order & Proceed to Payment
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default PaymentMethod;



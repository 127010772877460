import React, { useContext } from "react";
import { CartContext } from "./cartContext";
import { useNavigate } from 'react-router-dom';

const CartModal = ({ closeModal }) => {
  const { cartItems, removeFromCart } = useContext(CartContext);
  const totalItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalAmount = cartItems.reduce((total, item) => total + item.package_amount * item.quantity, 0);
  const navigate = useNavigate();

  const handleCheckout = () => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    localStorage.setItem("totalAmount", totalAmount.toString());
    navigate('/checkout', { state: { cartItems, totalAmount } });
    closeModal();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-2xl text-black font-semibold mb-4">Your Cart</h2>
        
        {cartItems.length === 0 ? (
          <p className="text-black">Your cart is empty.</p>
        ) : (
          <ul>
            {cartItems.map((item) => (
              <li key={item.id} className="mb-4 flex justify-between items-center">
                <span className="text-black">{item.package_name} (x{item.quantity})</span>
                <span className="text-black">$ {item.package_amount * item.quantity}</span>
                <button
                  className="text-red-500"
                  onClick={() => removeFromCart(item.id)}
                >
                  Remove 1
                </button>
              </li>
            ))}
          </ul>
        )}
        <div className="mt-4">
          <hr className="my-4" />
          <div className="flex justify-between">
            <span className="text-black font-semibold">Total Items:</span>
            <span className="text-black">{totalItemCount}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-black font-semibold">Total Amount:</span>
            <span className="text-black">$ {totalAmount.toFixed(2)}</span>
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            className="bg-orange-500 text-white py-2 px-4 rounded mr-4"
            // onClick={() => navigate('/payment')}
            // onClick={() => navigate('/checkout', { state: { cartItems, totalAmount } })}
            onClick={handleCheckout}
          >
            Checkout Method
          </button>
          <button
            className="bg-orange-500 text-white py-2 px-4 rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;



// import React, { useContext } from "react";
// import { CartContext } from "./cartContext";

// const CartModal = ({ closeModal }) => {
//   const { cartItems, removeFromCart } = useContext(CartContext);

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//       <div className="bg-white p-6 rounded-lg max-w-md w-full">
//         <h2 className="text-2xl text-black font-semibold mb-4">Your Cart</h2>
//         {cartItems.length === 0 ? (
//           <p className="text-black">Your cart is empty.</p>
//         ) : (
//           <ul>
//             {cartItems.map((item) => (
//               <li key={item.id} className="mb-4 flex justify-between items-center">
//                 <span className="text-black">{item.package_name} (x{item.quantity})</span>
//                 <span className="text-black">$ {item.package_amount * item.quantity}</span>
//                 <button
//                   className="text-red-500"
//                   onClick={() => removeFromCart(item.id)}
//                 >
//                   Remove 1
//                 </button>
//               </li>
//             ))}
//           </ul>
//         )}
//         <div className="mt-4 flex justify-end">
//           <button
//             className="bg-orange-500 text-white py-2 px-4 rounded"
//             onClick={closeModal}
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CartModal;


import React from 'react';

const Toast = ({ message, onClose }) => {
  return (
    <div 
      className="fixed top-20 right-4 bg-orange-500 text-white p-4 rounded shadow-lg transition-opacity duration-300" 
      style={{ zIndex: 9999 }} 
    >
      <span>{message}</span>
    </div>
  );
};

export default Toast;



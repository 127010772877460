import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { CartContext } from "../cartContext";
import { useToast } from "../newToast";

const PackageProvider = () => {
  const [packages, setPackages] = useState([]);
  const [showPackages, setShowPackages] = useState(false);
  const apiUrl = process.env.REACT_APP_PACKAGE_URL;
  const { addToCart } = useContext(CartContext);
  const { showToastMessage } = useToast();
 
  useEffect(() => {
    if (apiUrl) {
      axios
        .get(apiUrl)
        .then((response) => {
          setPackages(response.data);
          console.log("package: ", response);
        })
        .catch((error) => {
          console.error("Error fetching data from the API:", error);
        });
    }

    setTimeout(() => {
      setShowPackages(true);
    }, 1000);
  }, [apiUrl]);

  return (
    <section className="py-12 px-4">
      <div className="container mx-auto">
        <div className="text-center">
          {/* <h2 className="text-2xl font-semibold mb-4 text-orange-500">
            Our Packages
          </h2> */}
          <p className="text-white text-5xl font-bold mb-10 font-semibold">
            We provide a wide range of creative packages
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {packages.map((service, index) => (
            <div
              key={service.id}
              className={`p-6 border border-orange-500 rounded-lg text-center ${
                showPackages
                  ? "transition-opacity duration-500 transform translate-x-0 opacity-100 hover:shadow-md"
                  : "opacity-0 translate-x-16"
              }`}
              style={{
                transitionDelay: `${index * 0.6}s`,
              }}
            >
              <h3 className="text-xl font-semibold mb-2 text-white">
                {service.package_name}
              </h3>
              <p className="text-gray-400">
                {service.package_description.split("\n").map((line, index) => (
                    <span key={index}>
                    {line}
                    <br />
                    </span>
                ))}
              </p>
              <p className="text-gray-400 font-bold text-lg">$ {service.package_amount}</p>
              <button 
              onClick={() => {
                addToCart(service);
                showToastMessage(`Added ${service.package_name} to cart!`);
              }}
              className="bg-orange-500 mt-4 text-white hover:bg-orange-400 text-md lg:text-lg font-semibold px-4 py-2 rounded-full transition duration-300">
                ADD TO CART
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PackageProvider;



import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [showBlogs, setShowBlogs] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://source.codesignservices.com/blogs/");
        const data = await response.json();

        setBlogs(data);
        console.log("ggg",data)
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();

    setTimeout(() => {
        setShowBlogs(true);
      }, 1000);
  }, []);

  const handleSeeMore = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="max-w-6xl mx-auto py-8 px-4">
      <h2 className="text-3xl text-white font-bold mb-6">Our Blogs</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.map((blog , index) => (
          <div key={blog.id} className={`border border-2 border-orange-500 shadow-md rounded-lg p-6 ${
                showBlogs
                  ? "transition-opacity duration-500 transform translate-x-0 opacity-100 hover:shadow-md"
                  : "opacity-0 translate-x-16"
              }`}
              style={{
                transitionDelay: `${index * 0.6}s`,
              }}>
            <h3 className="text-xl text-white font-semibold mb-4">{blog.Title}</h3>
            <p className="text-gray-700 mb-4">
              {blog.Content ? `${blog.Content.slice(0, 100)}...` : "Content not available"}
            </p>
            <button
              onClick={() => handleSeeMore(blog.id)}
              className="text-orange-500 hover:underline font-medium"
            >
              See More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogSection;


// import React from "react";
// import { useNavigate } from "react-router-dom";

// const BlogSection = () => {
//   const navigate = useNavigate();

//   const blogs = [
//     {
//       id: 1,
//       title: "Blog Post 1",
//       content: "This is a preview of the first blog post...",
//       fullContent: "This is the full content of the first blog post."
//     },
//     {
//       id: 2,
//       title: "Blog Post 2",
//       content: "This is a preview of the second blog post...",
//       fullContent: "This is the full content of the second blog post."
//     },
//     {
//       id: 3,
//       title: "Blog Post 3",
//       content: "This is a preview of the third blog post...",
//       fullContent: "This is the full content of the third blog post."
//     }
//   ];

//   const handleSeeMore = (id) => {
//     navigate(`/blog/${id}`);
//   };

//   return (
//     <div className="max-w-6xl mx-auto py-8 px-4">
//       <h2 className="text-3xl text-white font-bold mb-6">Our Blogs</h2>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
//         {blogs.map((blog) => (
//           <div key={blog.id} className="border border-2 border-orange-500 shadow-md rounded-lg p-6">
//             <h3 className="text-xl text-white font-semibold mb-4">{blog.title}</h3>
//             <p className="text-gray-700 mb-4">{blog.content}</p>
//             <button
//               onClick={() => handleSeeMore(blog.id)}
//               className="text-orange-500 hover:underline font-medium"
//             >
//               See More
//             </button>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default BlogSection;

import React from "react";
import CreativeImage from "../../assets/images/new280.jpg";

const AboutCreative = () => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-between p-4 space-y-4 md:space-x-4 container mx-auto mt-5 flex py-16">
      <div className="md:w-1/2 pr-5">
        <h2 className="text-2sm font-bold mb-2 text-orange-500 mb-5">
          What We Are
        </h2>
        <p className=" text-white text-5xl mb-5">
          We are a dynamic team of creative people
        </p>
        <p className="text-gray-500 ">
          Looking for a reliable IT services partner to help you grow and
          succeed? Look no further than our agency. With years of experience and
          a commitment to excellence, we provide a wide range of IT services to
          businesses of all sizes and industries. From network setup and
          cybersecurity to cloud computing and software development, our team of
          experts has the knowledge and expertise to handle all your IT needs.
          We use the latest technology and best practices to ensure your systems
          are secure, efficient, and optimized for success. Trust us to be your
          go-to partner for all your IT needs and take your business to the next
          level.
        </p>
      </div>
      <div className="md:w-1/2">
        <img
          src={CreativeImage}
          alt="IT Services"
          className="max-w-full h-auto"
        />
      </div>
    </div>
  );
};

export default AboutCreative;
